import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from './baseQuery'
import { Patient } from '../types'

export const patientsBFFApi = createApi({
  reducerPath: 'patientsBFFApi',
  tagTypes: ['Patients_BFF'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/bff/patients',
  }),
  endpoints: (build) => ({
    getPatientByExternalId: build.query<Patient, { patientId: string }>({
      query: ({ patientId }) => {
        if (!patientId) {
          throw new Error('PatientId Id is invalid')
        }
        return {
          url: `/external/${patientId}`,
          method: 'get',
        }
      },
    }),
  }),
})

export const { useGetPatientByExternalIdQuery, useLazyGetPatientByExternalIdQuery } = patientsBFFApi
